
import Modal         from './Modal.svelte';
import SubscribeForm from './SubscribeForm.svelte';
import Play          from './Play.svelte';
import API           from './API.js'

const modal = new Modal({
	target: document.body,
	props: {}
});

const subscribe = new SubscribeForm({
	target: document.getElementById("subscribe_placeholder"),
	props:  {}
});	

const play = new Play({
	target: document.getElementById("play_placeholder"),
	props: {}
});

window.API_showAccount = function() {
	API.setRoute(API.ROUTE_LOGIN);
};

API.init(window.api_state);

export default app;

<script>
import { onMount }       from "svelte"
import API               from './API.js';


let servers = [];
API.servers.subscribe(function (u) { servers = u; });

onMount(() => {
	console.log("onMount AdminServers");
	API.request('admin/servers', null);
});
</script>

<h2>Servers ({servers.length})</h2>
<div class="container">
  {#each servers as server}
  <div class="row">
    <div class="col-md-12">
      {JSON.stringify(server) }
    </div>
  </div>
  {/each}
</div>


<style>
</style>
  
<script>
import API   from './API.js';
import Input from './Input.svelte';
import Submit from './Submit.svelte';

//https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// see autocompletes

let form = API.formCreate([
		///////////////////////////////////////////
		// Email
		///////////////////////////////////////////
		{
			"name":      "email", 
			"validator": function (v) {
				if (v.length == 0) {
					return "Email missing";
				}
				return null;
			}
		}
	]
);

let state   = 'default';
let promise = null;

function onSubmit() 
{	
	form  = API.formValidate(form)

	if (form.is_valid) 
	{
		promise = API.request('email-subscribe', form);
		promise.then(
			function (f) {
				console.log("IS THIS THE ACCEPT? " + f.success);
				form = f;
			}
		);
	}
}
</script>

{#if form.success}
	Thank You!
{:else}
<form on:submit|preventDefault={onSubmit}>

{#if form.error_global != '' }<span>{form.error_global}</span>{/if}
<div class="form-group">
	<h3>Road of Bones is under development.</h3>

	<label for="subscribe_email">Enter your email to get updates!</label>
	<Input id="subscribe_email" 
			bind:value={form.fields.email} 
			error={form.errors.email} />
   
</div>
<div class="form-group">


<Submit promise={promise} label="Sign up!" />

</div>
</form>
{/if}
<style>
</style>
  
<script>
import API   from './API.js';
import Input   from './Input.svelte';
import Submit   from './Submit.svelte';

let form = API.formCreate([
		///////////////////////////////////////////
		// Email
		///////////////////////////////////////////
		{
			"name":      "email", 
			"validator": function (v) {
				if (v.length == 0) {
					return "Email missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// Password
		///////////////////////////////////////////
		{
			"name":      "password",
			"validator": function(v) {
				if (v.length == 0) {
					return "Password missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// Password2
		///////////////////////////////////////////
		{
			"name":      "password_confirm",
			"validator": function(v) {
				if (v.length == 0) {
					return "Password missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// signup code
		///////////////////////////////////////////
		{
			"name":      "signup_code",
			"validator": function(v) {
				if (v.length == 0) {
					return "Signup code missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// signup code
		///////////////////////////////////////////
		{
			"name":      "name",
			"validator": function(v) {
				if (v.length == 0) {
					return "Name missing";
				}
				else if (v.length > 64) {
					return "Name too long. 64 character max.";
				}
				return null;
			}
		}
	]
);

let promise = null;
function onSubmit(event) 
{	
	form = API.formValidate(form);
	if (form.is_valid) {
		promise = API.request('account-register', form);
		promise.then(
			function(f) {
				promise = null;
				form    = f;
				if (f.success) {
					API.setRoute(API.ROUTE_ACCOUNT);
				}
			}
		); 
	}
}
</script>

<h2>Register</h2>


<form on:submit|preventDefault={onSubmit}>

{#if form.error_global != '' }<span>{form.error_global}</span>{/if}
<div class="form-group">
	<label for="register_signup_code" >Signup Code</label>
	<Input id="register_signup_code" 
			bind:value={form.fields.signup_code} 
			error={form.errors.signup_code} 
			/>
</div>
<div class="form-group">
	<label for="register_name" >User Name</label>
	<Input id="register_name" 
			bind:value={form.fields.name} 
			error={form.errors.name} 
			/>
</div>
<div class="form-group">
	<label for="register_email" >Email</label>
	<Input id="register_email" 
			bind:value={form.fields.email} 
			error={form.errors.email} 
			type="email"
			autocomplete="email"/>
</div>

<div class="form-group">
	<label for="register_password">Password</label>
	<Input id="register_password" 
			bind:value={form.fields.password} 
			error={form.errors.password} 
			type="password"
			autocomplete="new-password"/>
</div>
<div class="form-group">
	<label for="register_password_confirm">Confirm Password</label>
	<Input id="register_password_confirm" 
			bind:value={form.fields.password_confirm} 
			error={form.errors.password_confirm} 
			type="password"
			autocomplete="new-password"/>
</div>
<div class="form-group">
	<Submit promise={promise} label="Register" />
</div>
</form>
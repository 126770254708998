<style>
/* The Modal (background) */
.rmodal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.rmodalactive {
  display: block !important;
}

/* Modal Content */
.modal-content {
  background-color: #000;
  color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #110;
  width: 80%;
}

/* The Close Button */
.close {
  color: #999;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #666;
  text-decoration: none;
  cursor: pointer;
}
</style>

<script>
import AdminServers         from "./AdminServers.svelte"
import LoginForm         from "./LoginForm.svelte"
import RegisterForm      from "./RegisterForm.svelte"
import ResetPasswordForm from "./ResetPasswordForm.svelte"
import ForgotPasswordForm from "./ForgotPasswordForm.svelte"
import SubscriptionConfirmForm from './SubscriptionConfirmForm.svelte'
import { onMount }       from "svelte"
//import { easeIn } from 'svelte/easing';
import API               from './API.js';


let user = null;
API.user.subscribe(function (u) {
  user      = u;
});




let route = 'default';
let visible = false;


API.route.subscribe(
  function (r) 
{
  console.log("Modal.got route: " + r);
  if (r ==  API.ROUTE_ACCOUNT && !API.userIsLoggedIn(user)) {
    API.setRoute(API.ROUTE_LOGIN)
  }
  else if (r == API.ROUTE_LOGIN && API.userIsLoggedIn(user)) {
    API.setRoute(API.ROUTE_ACCOUNT)
  }
  else
  {
    route = r;
    visible = r.startsWith("/#m-");
  }
});


let modal_wrapper;
onMount(function() {
  //modal_wrapper.style.display = "block";
});







function onLogout() {
  API.request('account-logout', null).then(function(form){
    if (form.success) {
      API.setRoute(API.ROUTE_LOGIN);
    }
  });
}


function onCloseModal(e) {
    if (visible && e.target == modal_wrapper) {
      API.setRoute(API.ROUTE_ROOT);
    }
}

function onClickCloseModal(e) {
    if (visible) {
      API.setRoute(API.ROUTE_ROOT);
    }
}

function onKeyDown(event) {
  if (visible && event.key === "Escape") {
    API.setRoute(API.ROUTE_ROOT);
  }
}
</script>


<svelte:window on:click={onCloseModal} on:keydown={onKeyDown}/>
<!-- transition:fade|local  -->
<div bind:this={modal_wrapper} class="rmodal" class:rmodalactive={visible}>
  <div class="modal-content">
    <span class="close" on:click={onClickCloseModal}>&times;</span>
    <div class="container">
      <div class="row">      

<!-- registration and login form -->    
{#if route == API.ROUTE_LOGIN}
<div class="col-sm-6">
  <LoginForm />
</div>
<div class="col-sm-6">
  <RegisterForm />
</div>

<!-- forgot password form -->
{:else if route == API.ROUTE_FORGOT_PASSWORD}
<div class="col-sm-12">
    <ForgotPasswordForm />
</div>

<!-- reset password form -->
{:else if route == API.ROUTE_RESET_PASSWORD}
<div class="col-sm-12">
    <ResetPasswordForm />
</div>

<!-- reset password form -->
{:else if route == API.ROUTE_SUBSCRIPTION_CONFIRM}
<div class="col-sm-12">
    <SubscriptionConfirmForm />
</div>

<!-- account display form -->
{:else if route == API.ROUTE_ACCOUNT}
<h2>Account</h2>
{#if API.userIsAdmin(user)}
<a href="/#m-admin-servers">Servers</a>
{/if}
<div class="container">
  <div class="row">
    <div class="col-md-12">
    <button on:click={onLogout} >Logout</button>
    <div>{user._id}</div>
    <div>{user.name}</div>
    </div>
  </div>
</div>

<!-- account display form -->
{:else if route == API.ROUTE_ADMIN_SERVERS}
<AdminServers />
<!-- default -->
{:else}
<h2>Undefined</h2>
{/if}


      </div>
    </div>  
  </div>
</div>



<style>
</style>
  
<script>
import API   from './API.js';
import Input   from './Input.svelte';
import Submit   from './Submit.svelte';

let form = API.formCreate(
	[
		///////////////////////////////////////////
		// Password
		///////////////////////////////////////////
		{
			"name":      "password",
			"validator": function(v) {
				if (v.length == 0) {
					return "Password missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// Password2
		///////////////////////////////////////////
		{
			"name":      "password_confirm",
			"validator": function(v) {
				if (v.length == 0) {
					return "Password missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// Parameters passed in get fields
		///////////////////////////////////////////


		// TODO roll _k,_v into hash
		// 1:email-subscribe:rtc-324434:wetrwerwfrwerwerwre
		{	"name" : "_k",      "source": "get" },
		{	"name" : "_t",      "source": "get" },
		{	"name" : "_v",      "source": "get" },
		{	"name" : "_h",      "source": "get" },
		{	"name" : "user_id", "source": "get" },
		{	"name" : "action", "source": "get" }
	]
);

let promise = null;
function onSubmit(event) 
{	
	form = API.formValidate(form);
	if (form.is_valid) {
		promise = API.request('account-password-reset', form);
		promise.then(
			function(f) {
				promise = null;
				form    = f;
				if (f.success) {
					API.setRoute(API.ROUTE_ACCOUNT);
				}
			}
		); 
	}
}
</script>

<h2>Reset Password</h2>


<form on:submit|preventDefault={onSubmit}>

{#if form.error_global != '' }<span>{form.error_global}</span>{/if}
<div class="form-group">
Please enter your new password below.
</div>
<div class="form-group">


	<label for="reset_password">Password</label>
	<Input id="reset_password" 
			bind:value={form.fields.password} 
			error={form.errors.password} 
			type="password"
			autocomplete="new-password"/>
</div>
<div class="form-group">
	<label for="reset_password_confirm">Confirm Password</label>
	<Input id="reset_password_confirm" 
			bind:value={form.fields.password_confirm} 
			error={form.errors.password_confirm} 
			type="password"
			autocomplete="new-password"/>
</div>
<div class="form-group">
	<Submit promise={promise} label="Register" />
</div>
</form>
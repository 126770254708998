<script>
export let value;
export let error;
export let id;
export let type = "text";
export let autocomplete = "off";

function clearError() {
	error = '';
}
</script>

{#if type== "text"}
<input class="form-control" class:is-invalid={error != ''}  type="text"
	autocomplete={autocomplete} bind:value={value} id={id} on:change={clearError}>
{:else if type == "email"}
<input class="form-control" class:is-invalid={error != ''}  type="email"
	autocomplete={autocomplete} bind:value={value} id={id} on:change={clearError}>
{:else if type == "password"}
<input class="form-control" class:is-invalid={error != ''}  type="password"
	autocomplete={autocomplete} bind:value={value} id={id} on:change={clearError}>
{/if}
<div class="invalid-feedback">{error}</div>
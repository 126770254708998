import { writable } from 'svelte/store';

const BASE_URL = "/api/v1/";
let   s_features = {};

function setCookie(cname, cvalue, exdays) 
{
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function extractModels(json)
{

	if (json.hasOwnProperty("models")) {
		let models = json.models;
		for (let model_key in models) {
			if (!models.hasOwnProperty(model_key)) {
				continue;
			}
			let model = models[model_key];
			if (model_key == "user") {
				api.user.set(model);
			}
			else if (model_key == "servers") {
				api.servers.set(model);
			}
			else {
				console.log("Unsupported Model: " + model_key);
			}
		}
	}
}


let api = 
{
	FEATURE_AVAILABLE_TO_PUBLIC: 'AvailableToPublic',

	getFeature: function(name, defaux) {
		if (!s_features.hasOwnProperty(name)) {
			return defaux;
		}
		return s_features[name];
	},

	init: function (state) 
	{
		console.log("api init called");

		extractModels(state);

		s_features = state.features;

		(function()
		{
			console.log("Search: " + window.location.href + " => " + window.location.search);
			var qd = {};
			if (window.location.search) {
				let tokens = window.location.search.substr(1).split("&");
				console.log(tokens);
				tokens.forEach(function(item) {
			   		let s = item.split("=");
			    	let k = s[0];
			    	let v = s[1] && decodeURIComponent(s[1]); //  null-coalescing / short-circuit
			    	//(k in qd) ? qd[k].push(v) : qd[k] = [v]
			    	(qd[k] = qd[k] || []).push(v) // null-coalescing / short-circuit
				});
			}

			api.GET = qd;

			console.log("get fields");
			console.log(api.GET);


			console.log("pathname: " + window.location.pathname);
			console.log("window.location.hash: " + window.location.hash);

			let current_path = window.location.pathname;
			if (window.location.hash) {
				current_path += window.location.hash;
			}

			// see https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event

			let straight_get = (current_path.indexOf('?') != -1) ? 
				current_path.substr(0, current_path.indexOf('?')) : 
				current_path;

		 	if (!window.history.state) {
		 		console.log("replacing state");
		   		window.history.replaceState({path: current_path}, '',  window.location.href);
		  	}

		  	api.route.set(current_path);
		})();

		function onPopState(event)
		{
			console.log("onPopState");
			console.log(event);
			if (event.state != null) {
				console.log(">Existing: " + event.state.path);
  				api.route.set(event.state.path);
			}
			else 
			{
				let current_path = window.location.pathname;
				if (window.location.hash) {
					current_path += window.location.hash;
				}
				console.log(">New: " + current_path);

				// see https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
				if (!window.history.state) {
			 		console.log("replacing state");
			   		window.history.replaceState({path: current_path}, '',   window.location.href);
			  	}
			  	api.route.set(current_path);
			}
		}

		window.addEventListener('popstate', onPopState);


		console.log(state);
	},

	// the active route for the application
	route:       writable('undefined'),

	GET                    : {},
	ROUTE_ACCOUNT          :'/#m-account',
	ROUTE_ADMIN_SERVERS    :'/#m-admin-servers',
	ROUTE_LOGIN            :'/#m-login',
	ROUTE_FORGOT_PASSWORD  :'/#m-password-forgot',
	ROUTE_RESET_PASSWORD   :'/#m-password-reset',
	ROUTE_VALIDATE_EMAIL   :'/#m-email-verify',
	ROUTE_SUBSCRIPTION_CONFIRM: '/#m-subscription-confirm',
	ROUTE_ROOT             :'/',

	setRoute: function(route) {
		api.route.set(route);
		window.history.pushState({path: route}, '',   route);
	},

	servers: writable([]),

	// the active logged in user
	user: writable({
		'_id' :            null,
		'name':            'Traveller',
		'email' :          'Email',
		'email_verified' : false
	}),

	userIsLoggedIn: function(user) {
		// this'll change in the future
		return user._id != null;
	},	

	userIsAdmin: function(user) {
		return true;
	},
	
	showModal : function(v) {
		console.log("showModal: " + v);
	
	},

	

	formCreate: function(defn) {

		let form = {
			'success'    :  false,
			'is_valid'   :  false,
			'errors':       {},
			'fields':       {},
			'error_global': '',
			'defn':         defn
		};

		for (let k = 0; k < defn.length; k++) {
			var v = defn[k];
			form.errors[v.name] = '';
			form.fields[v.name] = '';
		}

		return form;
	},


	formValidate: function(src_form) 
	{
		let form = {
			'success'    :  false,
			'is_valid'   :  false,
			'errors':       {},
			'fields':       src_form.fields,
			'error_global': '',
			'defn':         src_form.defn
		};


		let status = true;
		form.error_global = '';
		for (let k = 0; k < form.defn.length; k++) {
			let v = form.defn[k];
			let vn = v.name;

			form.errors[v.name] = '';

			if (v.hasOwnProperty("source")) {
				if (v.source == "get") {
					if (!api.GET.hasOwnProperty(vn)) {
						status = false;
						form.error_global = "Field not found: " + vn;
					}
					else
					{
						form.fields[vn] = api.GET[vn];
					}
				}
			}

			if (v.hasOwnProperty("validator")) {
				let msg = v.validator(form.fields[v.name]);
				if (msg !== null) {
					form.errors[v.name] = msg;
					status = false;
				}
			}
		}

		form.is_valid = status;
		return form;
	},

	// TODO move this into the api
	request: function(endpoint, form) 
	{
		if (form == null) {
			form = api.formCreate([]);
		}
		//https://roadofbones.io/
		form = {...form};

		let promise = new Promise(
			function(accept, reject)
			{


			let data = form.fields;
			console.log(BASE_URL + endpoint);


			const formData = new FormData();
			for (let d in data) {
				if (data.hasOwnProperty(d)) {
					formData.append(d, data[d])
				}
			}

			let failure_sent = false;
			function simpleFailure(failure) {
				if (failure_sent) {
					return;
				}

				failure_sent = true;
				form.success = false;
				accept(form);
			}

			fetch(BASE_URL + endpoint, 
			{
	       	 	method:      'POST', // *GET, POST, PUT, DELETE, etc.
	        	mode:        'no-cors', // no-cors, *cors, same-origin
	        	cache:       'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	        	credentials: 'include',	//'same-origin', // include, *same-origin, omit
	        	headers: {
	            	'Content-Type': 'application/json',
	            },
	        	redirect: 'follow', // manual, *follow, error
	        	referrer: 'client', //'no-referrer', // no-referrer, *client
	        	body: formData
	        	//body: JSON.stringify(data), // body data type must match "Content-Type" header
	    	})
			.then(
				function(response) 
				{
					if (response.status != 200) {
						simpleFailure(response);
						return null;
					}
					else {
						return response.json();
					}
				},
				simpleFailure
			)
			.then(
				function(json) 
				{
					if (json == null) {
						simpleFailure(json);
					}
					else
					{
						extractModels(json);

						form.success  = json.success;
						form.fields   = json.fields;
						form.errors   = json.errors;
						form.response = json;

						accept(form);
					}
				},
				simpleFailure);
		});
		return promise;
	}

};


export default api;
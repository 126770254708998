<style>
	.playbutton {
		width: 100%;
	}

	.rcheckmark {
		content:url("./iconmonstr-check-mark-1.svg");
	}
</style>

<script>


import API   from './API.js';
import Input   from './Input.svelte';
import Submit   from './Submit.svelte';


let user = null;
API.user.subscribe(function (u) {
  user      = u;
});



let support = "Your Browser is Supported!";
let supported = false;
{
	const gl = document.createElement('canvas').getContext('webgl2');
	if (!gl) 
	{
		if (typeof WebGL2RenderingContext !== 'undefined') {
			support = ('Your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers');
		} 
		else 
		{
			support = ('Your browser has no WebGL2 support at all'); 
			
		}
	} else {
	  console.log('webgl2 works!');
	  supported = true;
	}
}

let form = API.formCreate([]);
let promise = null;
function onPlay() 
{	
	form = API.formValidate(form);
	if (form.is_valid)
	{
		promise = API.request('play', form);
		promise.then(function(f) {
			form = f;
			if (form.success) {
				window.location = form.response.target;
			}
			promise = null;
		});
	}
}

</script>

<div>{#if supported} <img class="rcheckmark"  alt="checkmark">{/if} {support} </div>
{#if supported && (API.getFeature(API.FEATURE_AVAILABLE_TO_PUBLIC, false) || API.userIsLoggedIn(user))}
<div>
<form on:submit|preventDefault={onPlay}>
<Submit label="Play Now" promise={promise} />
</form>
</div>
{/if}
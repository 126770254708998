<script>
export let label;
export let promise;
</script>


<button type="submit" disabled={promise != null}  class="btn btn-primary">
	{#if promise != null} 
		<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
		{label}
	{:else}
		{label}
	{/if}
</button>
<style>
</style>
  
<script>
import API   from './API.js';
import Submit from './Submit.svelte';
import Input  from './Input.svelte';

//https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// see autocompletes


let state = 'IDLE';

let form = API.formCreate([
		///////////////////////////////////////////
		// Email
		///////////////////////////////////////////
		{
			"name":      "email", 
			"validator": function (v) {
				if (v.length == 0) {
					return "Email missing";
				}
				return null;
			}
		}
	]
);


let promise = null;
function onSubmit() 
{	
	form = API.formValidate(form);

	if (form.is_valid) 
	{
		promise = API.request('account-password-forgot', form);
		promise.then(function(f) {
			form = f;
			if (f.success) {
				state = 'SENT';
			}
		});
	}
}


</script>

<h2>Reset Password</h2>

{#if state == "IDLE"}


<form on:submit|preventDefault={onSubmit}>
	<div class="form-group">We'll send you an email to get started.</div>
	{#if form.error_global != '' }<span>{form.error_global}</span>{/if}
	<div class="form-group">
		<label for="reset_email">Email:</label>
		<Input id="reset_email" 
			bind:value={form.fields.email} 
			error={form.errors.email} 
			type="email"
			/>
	</div>
	<div class="form-group">
		<Submit promise={promise} label="Reset" />
	</div>
	<div class="form-group">Return to <a href="/#m-login">login</a> &gt; </div>
</form>

{:else}
	<div>An email has been sent to your inbox.</div>
	<div>Return to <a href="/#m-login">login</a> &gt; </div>
{/if}



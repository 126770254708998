<style>
</style>
  
<script>
import API   from './API.js';
import Input   from './Input.svelte';
import Submit   from './Submit.svelte';

let form = API.formCreate(
	[
		///////////////////////////////////////////
		// Parameters passed in get fields
		///////////////////////////////////////////
		{	"name" : "_k",      "source": "get" },
		{	"name" : "_t",      "source": "get" },
		{	"name" : "_v",      "source": "get" },
		{	"name" : "_h",      "source": "get" },
		{	"name" : "email", "source": "get" },
		{	"name" : "action", "source": "get" }
	]
);

let complete = false;
let promise = null;
function onSubmit(event) 
{	
	form = API.formValidate(form);
	if (form.is_valid) {
		promise = API.request('subscription-confirm', form);
		promise.then(
			function(f) 
			{
				promise = null;
				form    = f;
				if (f.success) {
					complete = true;
				} 
			}
		); 
	}
}
</script>

<h2>Confirm Email Subscription</h2>

	<form on:submit|preventDefault={onSubmit}>

	{#if form.error_global != '' }<span>{form.error_global}</span>{/if}
	<div class="form-group">
	Click the button below to confirm your subscription.
	</div>
	<div class="form-group">
		<Submit promise={promise} label="Confirm Subscription" />
	</div>

	<h3>Thank You!</h3>

</form>
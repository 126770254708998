<style>
</style>
  
<script>
import API   from './API.js';
import Input   from './Input.svelte';
import Submit from './Submit.svelte';

//https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// see autocompletes

let promise = null;
let form = API.formCreate([
		///////////////////////////////////////////
		// Email
		///////////////////////////////////////////
		{
			"name":      "email", 
			"validator": function (v) {
				if (v.length == 0) {
					return "Email missing";
				}
				return null;
			}
		},
		///////////////////////////////////////////
		// Password
		///////////////////////////////////////////
		{
			"name":      "password",
			"validator": function(v) {
				if (v.length == 0) {
					return "Password missing";
				}
				return null;
			}
		}
	]
);

function onResetPassword() {
	API.setRoute('reset-password');
}

function onSubmit() 
{	
	form = API.formValidate(form);
	if (form.is_valid)
	{
		promise = API.request('account-login', form);
		promise.then(function(f) {
			form = f;
			if (form.success) {
				API.setRoute('account');
			}
			promise = null;
		});
	}
}
</script>

<h2>Login</h2>

<form on:submit|preventDefault={onSubmit}>
{#if form.error_global != '' }<span>{form.error_global}</span>{/if}
<div class="form-group">
	<label for="login_email">Email</label>
	<Input id="login_email" 
			bind:value={form.fields.email} 
			error={form.errors.email} />
</div>
<div class="form-group">
	<label for="login_password">Password</label>
	<Input id="login_password" 
			bind:value={form.fields.password} 
			error={form.errors.password} 
			type="password"/>
</div>
<div class="form-group">
<Submit label="Login" promise={promise} />
</div>
<div>Forgot your password? Reset it <a href="/#m-password-forgot">here</a>.</div>

</form>